<template>
    <div style="padding-top: 15px;">
        <div>
            <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Point detail image settings</label>
            </div>
            <label class="label"
            >Change Height <b>{{ content.styles.points.list_img_height }}</b> px</label>
            <div class="slider">
                <v-slider
                        hide-details
                        :min="10"
                        :max="600"
                        v-model="content.styles.points.list_img_height"
                ></v-slider>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"typeTrailPointsDetailStyles",
        props:['content'],
    }
</script>

<style lang="scss" scoped>

</style>