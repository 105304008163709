<template>
  <div>
    <div
      style="
        display: flex;
        align-items: center;
        margin-bottom: 15px;
      "
      :style="`justify-content:flex-end;`"
    >
        <div style="display: flex;justify-content: flex-end;align-items: center;">
            <v-btn v-if="!topText&&!content.styleSettings"
                   style="margin-right: 15px;"
                    class="object__submit btn btn_primary"
                    color="primary"
                    outlined
                    small
                    solo
                    @click="createTrailShow"
            >
                Create trail
            </v-btn>
            <div v-if="topText"
                 style="margin-right: 15px;">
                <v-btn color="primary" outlined @click="openTopText" small
                ><v-icon>mdi-chevron-left</v-icon>
                    <span>Back to trails list</span>
                </v-btn
                >
            </div>
            <div v-if="content.styleSettings"
                 style="margin-right: 15px;">
                <v-btn color="primary" outlined @click="openStyles" small
                ><v-icon>mdi-chevron-left list</v-icon>
                    <span
                    >Back to trails</span
                    ></v-btn
                >
            </div>
        </div>
    </div>
    <div v-if="!topText">
      <div
        v-if="!content.styleSettings"
      >
        <label class="label" style="margin-bottom: 0">Trails list</label>
      </div>
      <div style="margin-bottom: 15px" v-if="!content.styleSettings">
        <v-data-table
          class="table"
          :headers="headers"
          :items="trailList"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:body="{ items }">
            <tbody v-if="items.length">
              <tr v-for="item in items" :key="item.id">
                <td class="d-block d-sm-table-cell" style="text-align: center">{{ item.id }}</td>
                <td class="d-block d-sm-table-cell" style="text-align: center">
                  {{ item.name[landing.current_lang] }}
                </td>
                <td class="d-block d-sm-table-cell"
                  style="text-align: center; cursor: pointer"
                  @click="$emit('openTrailPoints', item)"
                >
                  {{ item.route_points.length }}
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center">
                  {{ formatDate(item.created_at) }}
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center">
                  <v-chip
                    class="ma-2"
                    :color="item.active ? 'green' : 'red'"
                    text-color="white"
                    style="cursor: pointer"
                    @click="changeStatus(item)"
                  >
                    {{ item.active ? "Active" : "Inactive" }}
                  </v-chip>
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center">
                  <v-menu offset-y open-on-hover bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark outlined small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          style="cursor: pointer"
                          @click="$emit('openMap', item)"
                          >Map Settings</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        style="cursor: pointer"
                        @click="showEdit(item)"
                      >
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          style="cursor: pointer; color: red"
                          @click="showDelete(item.id)"
                          >Delete</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          style="cursor: pointer"
                          @click="$emit('openTrailPoints', item)"
                        >
                          List points
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
            <tbody v-if="items.length === 0">
              <tr>
                <td class="d-block d-sm-table-cell"
                  colspan="6"
                  style="
                    text-align: center;
                    padding: 0 15px;
                    font-weight: normal;
                  "
                >
                  You don't have trails yet, it's time to create them
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>

      <div v-if="content.styleSettings">
        <typeTrailListStyles :content="content" />
      </div>
    </div>

    <div v-if="topText">
      <div class="label">Change your top text</div>
      <div>
        <editor
          v-if="showMce"
          v-model="content.styles.trail.top_text[landing.current_lang]"
          api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
          :init="options"
        />
      </div>
    </div>
    <!--Create new trail dialog-->
    <v-dialog
      v-model="dialogCreateTrail"
      @click:outside="closeDialogCreateTrail"
      max-width="710"
      :retain-focus="false"
      eager
    >
      <v-card>
        <v-card-title
          ><span>Create trail</span>
          <v-icon
            style="position: absolute; top: 10px; right: 10px"
            @click="closeDialogCreateTrail"
            >mdi-close</v-icon
          ></v-card-title
        >
        <v-card-text>
          <div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-evenly;
              "
            >
              <div style="flex: 1; margin-bottom: 15px">
                <div class="label" style="margin-bottom: 10px">
                  Choose your main image
                </div>
                <vue-dropzone
                  id="createDropzone1"
                  :options="dropzoneOptions"
                  :destroyDropzone="true"
                  ref="createDropzone"
                  @vdropzone-sending="customEvent"
                  @vdropzone-success="handleSuccessTrailMainImage"
                  @vdropzone-removed-file="handleRemoveTrailMainImage"
                >
                </vue-dropzone>
              </div>
            </div>
            <div style="flex: 1; margin-bottom: 15px">
              <div class="label" style="margin-bottom: 10px">
                Choose your detail image
              </div>
              <vue-dropzone
                id="createDropzone2"
                :options="dropzoneOptions"
                :destroyDropzone="true"
                ref="createDropzoneDetail"
                @vdropzone-sending="customEvent"
                @vdropzone-success="handleSuccessTrailDetailImage"
                @vdropzone-removed-file="handleRemoveTrailDetailImage"
              >
              </vue-dropzone>
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="flex: 1; padding-right: 15px">
                <div class="label" style="margin-bottom: 15px">Distance</div>
                <v-text-field
                  outlined
                  dense
                  v-model="createdTrail.distance"
                  type="number"
                  placeholder="Your trail distance"
                >
                </v-text-field>
              </div>
              <div style="flex: 1">
                <div class="label" style="margin-bottom: 15px">Time</div>
                <v-text-field
                  outlined
                  dense
                  v-model="createdTrail.time"
                  placeholder="Your trail time"
                >
                </v-text-field>
              </div>
              <div style="flex: 1; padding-left: 15px">
                <div class="label" style="margin-bottom: 15px">Difficult</div>
                <v-select
                  :items="difficultTypes"
                  outlined
                  dense
                  v-model="createdTrail.difficult"
                  placeholder="Your trail difficult"
                >
                </v-select>
              </div>
            </div>

            <v-tabs v-model="activeLang" style="margin-bottom: 30px">
              <v-tab
                v-for="(lang, i) in landing.selected_langs"
                :key="lang + i + 'select'"
                @change="changeLang(lang)"
              >
                <img
                  :src="
                    landing.advanced.langs.find((x) => x.value === lang).flag
                  "
                  alt=""
                  style="width: 20px; height: 15px; margin-right: 5px"
                />
                {{ lang }}
              </v-tab>
              <v-tab-item
                style="padding-top: 15px"
                v-for="(lang, i) in landing.selected_langs"
                :key="lang + i + 'selected'"
              >
                <div style="display: flex; justify-content: space-between">
                  <div style="padding-right: 15px; flex: 1">
                    <div class="label" style="margin-bottom: 15px">Name</div>
                    <v-text-field
                      outlined
                      dense
                      v-model="createdTrail.name[lang]"
                      placeholder="Your trail name"
                    ></v-text-field>
                  </div>
                  <div style="padding-left: 15px; flex: 1">
                    <div class="label" style="margin-bottom: 15px">
                      Video Link
                    </div>
                    <v-text-field
                      outlined
                      dense
                      v-model="createdTrail.video_link[lang]"
                      placeholder="Your trail video link"
                    ></v-text-field>
                  </div>
                </div>
                <div style="margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Small description
                  </div>
                  <editor
                    v-if="showMce"
                    v-model="createdTrail.small_description[lang]"
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="optionsSmall"
                  />
                </div>
                <div>
                  <div class="label" style="margin-bottom: 15px">
                    Description
                  </div>
                  <editor
                    v-if="showMce"
                    v-model="createdTrail.description[lang]"
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                  />
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            small
            outlined
            style="margin-bottom: 10px;"
            color="primary"
            @click="createNewTrail"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Create new trail dialog-->

    <!--Edit trail dialog-->
    <v-dialog
      v-model="dialogEditTrail"
      max-width="710"
      @click:outside="closeDialogEditTrail"
      :retain-focus="false"
      eager
    >
      <v-card>
        <v-card-title
          ><span>Edit trail</span>
          <v-icon
            style="position: absolute; top: 10px; right: 10px"
            @click="closeDialogEditTrail"
            >mdi-close</v-icon
          ></v-card-title
        >
        <v-card-text>
          <div style="padding: 30px">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-evenly;
              "
            >
              <div style="flex: 1; margin-bottom: 15px">
                <div class="label" style="margin-bottom: 10px">
                  Choose your main image
                </div>
                <vue-dropzone
                  id="editDropzone"
                  :options="dropzoneOptions"
                  :destroyDropzone="true"
                  ref="editDropzone"
                  @vdropzone-mounted="editDropzoneMount"
                  @vdropzone-sending="customEvent"
                  @vdropzone-success="handleSuccessEditTrailMainImage"
                  @vdropzone-removed-file="handleRemoveEditTrailMainImage"
                >
                </vue-dropzone>
              </div>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-evenly;
              "
            >
              <div style="flex: 1; margin-bottom: 15px">
                <div class="label" style="margin-bottom: 10px">
                  Choose your detail image
                </div>
                <vue-dropzone
                  id="editDropzoneDetail"
                  :options="dropzoneOptions"
                  :destroyDropzone="true"
                  ref="editDropzoneDetail"
                  @vdropzone-mounted="editDropzoneMountDetail"
                  @vdropzone-sending="customEvent"
                  @vdropzone-success="handleSuccessEditTrailDetailImage"
                  @vdropzone-removed-file="handleRemoveEditTrailDetailImage"
                >
                </vue-dropzone>
              </div>
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="flex: 1; padding-right: 15px">
                <div class="label" style="margin-bottom: 15px">Distance</div>
                <v-text-field
                  outlined
                  dense
                  v-model="editedTrail.distance"
                  type="number"
                  placeholder="Your trail distance"
                >
                </v-text-field>
              </div>
              <div style="flex: 1">
                <div class="label" style="margin-bottom: 15px">Time</div>
                <v-text-field
                  outlined
                  dense
                  v-model="editedTrail.time"
                  placeholder="Your trail time"
                >
                </v-text-field>
              </div>
              <div style="flex: 1; padding-left: 15px">
                <div class="label" style="margin-bottom: 15px">Difficult</div>
                <v-select
                  :items="difficultTypes"
                  outlined
                  dense
                  v-model="editedTrail.difficult"
                  placeholder="Your trail difficult"
                >
                </v-select>
              </div>
            </div>

            <v-tabs v-model="activeLang" style="margin-bottom: 30px">
              <v-tab
                v-for="(lang, i) in landing.selected_langs"
                :key="lang + i + 'select'"
                @change="changeLang(lang)"
              >
                <img
                  :src="
                    landing.advanced.langs.find((x) => x.value === lang).flag
                  "
                  alt=""
                  style="width: 20px; height: 15px; margin-right: 5px"
                />
                {{ lang }}
              </v-tab>
              <v-tab-item
                style="padding-top: 15px"
                v-for="(lang, i) in landing.selected_langs"
                :key="lang + i + 'selected'"
              >
                <div style="margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 15px">Name</div>
                  <v-text-field
                    outlined
                    dense
                    style="max-width: 50%"
                    v-model="editedTrail.name[lang]"
                    placeholder="Your trail name"
                  ></v-text-field>
                </div>
                <div>
                  <div class="label" style="margin-bottom: 15px">
                    Small Description
                  </div>
                  <editor
                    v-if="showMce"
                    v-model="editedTrail.small_description[lang]"
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="optionsSmall"
                  />
                </div>
                <div>
                  <div class="label" style="margin-bottom: 15px">
                    Description
                  </div>
                  <editor
                    v-if="showMce"
                    v-model="editedTrail.description[lang]"
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                  />
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
          small
          outlined
            style=" margin-bottom: 10px; max-width: 360px"
            color="primary"
            @click="editTrailConfirm"
            >Edit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Edit trail dialog-->

    <PopupSuccess ref="PopupSuccess" :title="'Thank you!'" :content="successMessage" />
    <PopupError :title="error" ref="PopupError" />
    <PopupDelete
      ref="PopupDelete"
      title="Are your sure about delete trail?"
      @confirm="deleteTrailConfirm"
    />
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";
import PopupSuccess from "@/components/Popup/PopupSuccess.vue";
import PopupError from "@/components/Popup/PopupError.vue";
import PopupDelete from "@/components/Popup/PopupDelete.vue";
import ImagesService from "@/services/images.service.js";
import typeTrailListStyles from "./typeTrailListStyles.vue";
export default {
  name: "typeTrails",
  components: {
    vueDropzone,
    PopupSuccess,
    PopupError,
    PopupDelete,
    typeTrailListStyles,
  },
  props: ["content", "item", "selectedMenuID"],
  computed: {
    ...mapState(["landing"]),
    ...mapState("trails", ["trailList"]),
    activeLang: {
      set: function (val) {},
      get: function () {
        return this.landing.advanced.selected_langs.indexOf(
          this.landing.current_lang
        );
      },
    },
  },
  data() {
    return {
      topText: false,
      deleteID: null,
      showMce: false,
      mapSettings: false,
      difficultTypes: ["easy", "medium", "hard"],
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: "jpg,svg,webp,gif,png",
        images_upload_handler: async (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), {
            lastModified: new Date().getTime(),
            type: blobInfo.blob().type,
          });
          formData.append(
            "lending_settings_id",
            this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
          formData.append("file", _file);
          const { data } = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}upload-image`,
            formData
          );
          success(data);
        },
        plugins:
          "preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",
        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
            );
            success(data);
          },
          plugins:
            "preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify |  numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 400,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",
        setup: function (editor) {
          editor.ui.registry.addButton("custom_button", {
            text: "Add Button",
            onAction: function () {
              // Open a Dialog
              editor.windowManager.open({
                title: "Add custom button",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "button_label",
                      label: "Button label",
                      flex: true,
                    },
                    {
                      type: "input",
                      name: "button_href",
                      label: "Button href",
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_target",
                      label: "Target",
                      items: [
                        { text: "None", value: "" },
                        { text: "New window", value: "_blank" },
                        { text: "Self", value: "_self" },
                        { text: "Parent", value: "_parent" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_rel",
                      label: "Rel",
                      items: [
                        { text: "No value", value: "" },
                        { text: "Alternate", value: "alternate" },
                        { text: "Help", value: "help" },
                        { text: "Manifest", value: "manifest" },
                        { text: "No follow", value: "nofollow" },
                        { text: "No opener", value: "noopener" },
                        { text: "No referrer", value: "noreferrer" },
                        { text: "Opener", value: "opener" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_style",
                      label: "Style",
                      items: [
                        { text: "Success", value: "success" },
                        { text: "Info", value: "info" },
                        { text: "Warning", value: "warning" },
                        { text: "Error", value: "error" },
                      ],
                      flex: true,
                    },
                  ],
                },
                onSubmit: function (api) {
                  var html =
                    '<a href="' +
                    api.getData().button_href +
                    '" class="v-btn v-btn--outlined theme--light v-size--small ' +
                    api.getData().button_style +
                    '--text" rel="' +
                    api.getData().button_rel +
                    '" target="' +
                    api.getData().button_target +
                    '">' +
                    api.getData().button_label +
                    "</a>";

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: "Close",
                    type: "cancel",
                    onclick: "close",
                  },
                  {
                    text: "Insert",
                    type: "submit",
                    primary: true,
                    enabled: false,
                  },
                ],
              });
            },
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
        optionsSmall: {
            images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
            images_file_types: "jpg,svg,webp,gif,png",
            images_upload_handler: async (blobInfo, success, failure) => {
                let formData = new FormData();
                let _file = new File([blobInfo.blob()], blobInfo.filename(), {
                    lastModified: new Date().getTime(),
                    type: blobInfo.blob().type,
                });
                formData.append(
                    "lending_settings_id",
                    this.$store.state.landing.settings_id
                );
                formData.append("image", _file);
                formData.append("file", _file);
                const { data } = await this.$axios.post(
                    `${process.env.VUE_APP_API_URL}upload-image`,
                    formData
                );
                success(data);
            },
            plugins:
                "preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",
            mobile: {
                images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
                images_file_types: "jpg,svg,webp,gif,png",
                images_upload_handler: async (blobInfo, success, failure) => {
                    let formData = new FormData();
                    let _file = new File([blobInfo.blob()], blobInfo.filename(), {
                        lastModified: new Date().getTime(),
                        type: blobInfo.blob().type,
                    });
                    formData.append(
                        "lending_settings_id",
                        this.$store.state.landing.settings_id
                    );
                    formData.append("image", _file);
                    formData.append("file", _file);
                    const { data } = await this.$axios.post(
                        `${process.env.VUE_APP_API_URL}upload-image`,
                        formData
                    );
                    success(data);
                },
                plugins:
                    "preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
            },
            menu: {
                tc: {
                    title: "Comments",
                    items: "addcomment showcomments deleteallconversations",
                },
            },
            menubar: "file edit view insert format tools table tc help",
            toolbar:
                "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify |  numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
            autosave_ask_before_unload: true,min_height:200,
            autosave_interval: "30s",
            autosave_prefix: "{path}{query}-{id}-",
            autosave_restore_when_empty: false,
            autosave_retention: "2m",
            image_advtab: true,
            link_list: [
                { title: "My page 1", value: "https://www.tiny.cloud" },
                { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_list: [
                { title: "My page 1", value: "https://www.tiny.cloud" },
                { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_class_list: [
                { title: "None", value: "" },
                { title: "Some class", value: "class-name" },
            ],
            importcss_append: true,
            templates: [
                {
                    title: "New Table",
                    description: "creates a new table",
                    content:
                        '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
                },
                {
                    title: "Starting my story",
                    description: "A cure for writers block",
                    content: "Once upon a time...",
                },
                {
                    title: "New list with dates",
                    description: "New List with dates",
                    content:
                        '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                },
            ],
            template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
            template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
            height: 250,
            image_caption: true,
            quickbars_selection_toolbar:
                "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
            noneditable_noneditable_class: "mceNonEditable",
            toolbar_mode: "sliding",
            contextmenu: "link image imagetools table configurepermanentpen",
            skin: "oxide",
            setup: function (editor) {
                editor.ui.registry.addButton("custom_button", {
                    text: "Add Button",
                    onAction: function () {
                        // Open a Dialog
                        editor.windowManager.open({
                            title: "Add custom button",
                            body: {
                                type: "panel",
                                items: [
                                    {
                                        type: "input",
                                        name: "button_label",
                                        label: "Button label",
                                        flex: true,
                                    },
                                    {
                                        type: "input",
                                        name: "button_href",
                                        label: "Button href",
                                        flex: true,
                                    },
                                    {
                                        type: "selectbox",
                                        name: "button_target",
                                        label: "Target",
                                        items: [
                                            { text: "None", value: "" },
                                            { text: "New window", value: "_blank" },
                                            { text: "Self", value: "_self" },
                                            { text: "Parent", value: "_parent" },
                                        ],
                                        flex: true,
                                    },
                                    {
                                        type: "selectbox",
                                        name: "button_rel",
                                        label: "Rel",
                                        items: [
                                            { text: "No value", value: "" },
                                            { text: "Alternate", value: "alternate" },
                                            { text: "Help", value: "help" },
                                            { text: "Manifest", value: "manifest" },
                                            { text: "No follow", value: "nofollow" },
                                            { text: "No opener", value: "noopener" },
                                            { text: "No referrer", value: "noreferrer" },
                                            { text: "Opener", value: "opener" },
                                        ],
                                        flex: true,
                                    },
                                    {
                                        type: "selectbox",
                                        name: "button_style",
                                        label: "Style",
                                        items: [
                                            { text: "Success", value: "success" },
                                            { text: "Info", value: "info" },
                                            { text: "Warning", value: "warning" },
                                            { text: "Error", value: "error" },
                                        ],
                                        flex: true,
                                    },
                                ],
                            },
                            onSubmit: function (api) {
                                var html =
                                    '<a href="' +
                                    api.getData().button_href +
                                    '" class="v-btn v-btn--outlined theme--light v-size--small ' +
                                    api.getData().button_style +
                                    '--text" rel="' +
                                    api.getData().button_rel +
                                    '" target="' +
                                    api.getData().button_target +
                                    '">' +
                                    api.getData().button_label +
                                    "</a>";

                                // insert markup
                                editor.insertContent(html);

                                // close the dialog
                                api.close();
                            },
                            buttons: [
                                {
                                    text: "Close",
                                    type: "cancel",
                                    onclick: "close",
                                },
                                {
                                    text: "Insert",
                                    type: "submit",
                                    primary: true,
                                    enabled: false,
                                },
                            ],
                        });
                    },
                });
            },
            content_css: ["/custom_tinymce.css"],
        },
      successMessage: "",
      categoryEditFile: false,
      categoryCreateFile: false,
      error: "",
      headers: [
        { text: "ID", value: "id", align: "center", sortable: true },
        { text: "Name", value: "name", align: "center", sortable: true },
        {
          text: "Points count",
          value: "route_points",
          align: "center",
          sortable: true,
        },
        {
          text: "Created",
          value: "created_at",
          align: "center",
          sortable: true,
        },
        {
          text: "Status",
          value: "active",
          align: "center",
          sortable: true,
        },
        { text: "Options", align: "center", sortable: true },
      ],
      styles: false,
      defaultTrail: {
        map: {
          coordinates: {
            lat: 45.81,
            lon: 15.98,
          },
          zoom: 9,
          travel_mode: "DRIVING",
        },
        images: [],
        hotel_id: null,
        menu_id: null,
        distance: 0,
        difficult: "easy",
        time: "",
        main_img: "",
        video_link: {
          en: "",
        },
        name: {
          en: "default name",
        },
        small_description: {
          en: "<p>default description</p>",
        },
        active: 1,
        description: {
          en: "<p>default description</p>",
        },
        route: [],
        route_points: [],
      },
      createdTrail: {
        map: {
          coordinates: {
            lat: 45.81,
            lon: 15.98,
          },
          zoom: 9,
          travel_mode: "DRIVING",
        },
        hotel_id: null,
        menu_id: null,
        distance: 0,
        difficult: "easy",
        time: "",
        main_img: "",
        images: [],
        video_link: {
          en: "",
        },
        name: {
          en: "default name",
        },
        small_description: {
          en: "<p>default description</p>",
        },
        description: {
          en: "<p>default description</p>",
        },
        active: 1,
        route: [],
        route_points: [],
      },
      editedTrail: {
        map: {
          coordinates: {
            lat: 45.81,
            lon: 15.98,
          },
          zoom: 9,
          travel_mode: "DRIVING",
        },
        video_link: {
          en: "",
        },
        hotel_id: null,
        menu_id: null,
        distance: 0,
        difficult: "easy",
        time: "",
        main_img: "",
        images: [],
        name: {
          en: "default name",
        },
        small_description: {
          en: "<p>default description</p>",
        },
        description: {
          en: "<p>default description</p>",
        },
        active: 1,
        route: [],
        route_points: [],
      },
      dialogCreateTrail: false,
      dialogEditTrail: false,
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        maxfiles: 3,
        thumbnailMethod: "contain",
        acceptedfiles: "image/*",
        addRemoveLinks: true,
      },
    };
  },
  methods: {
    ...mapActions("trails", [
      "createTrail",
      "getTrailList",
      "editTrail",
      "deleteTrail",
    ]),
    ...mapMutations("trails", ["setTrail"]),
    openTopText() {
      this.topText = !this.topText;
    },
    handleSuccessEditTrailDetailImage(file) {
      this.editedTrail.images.push(file.xhr.response);
    },
    handleSuccessTrailDetailImage(file) {
      this.createdTrail.images.push(file.xhr.response);
    },
    async handleRemoveTrailDetailImage(file) {
      if (this.createdTrail.images.includes(file.xhr.response)) {
        this.createdTrail.images.splice(
          this.createdTrail.images.indexOf(file.xhr.response),
          1
        );
        await ImagesService.deleteImage(file.xhr.response);
      }
    },
    closeMapSettings() {
      this.mapSettings = false;
      this.setTrail(null);
    },
    openMapSettings(trail) {
      this.setTrail(trail);
      this.mapSettings = true;
    },
    async deleteTrailConfirm() {
      await this.deleteTrail(this.deleteID);
      this.successMessage = "Your trail deleted succesfully";
      this.$refs.PopupSuccess.open();
    },
    showDelete(id) {
      this.deleteID = id;
      this.$refs.PopupDelete.open();
    },
    closeDialogEditTrail() {
      this.dialogEditTrail = false;
      this.editedTrail = JSON.parse(JSON.stringify(this.defaultTrail));
    },
    async editTrailConfirm() {
      this.editedTrail.distance = +this.editedTrail.distance;
      await this.editTrail(this.editedTrail);
      this.successMessage = "Your trail edited succesfully";
      this.dialogEditTrail = false;
      this.editedTrail = JSON.parse(JSON.stringify(this.defaultTrail));
      this.$refs.PopupSuccess.open();
    },
    showEdit(trail) {
      this.editedTrail = JSON.parse(JSON.stringify(trail));
      if (this.$refs.editDropzone) {
        this.$refs.editDropzone.removeAllFiles();
        if (this.editedTrail.main_img) {
          let mockFile = {
            accepted: true,
            cropped: true,
            kind: "image",
            ...this.editedTrail,
          };
          this.$refs.editDropzone.manuallyAddFile(
            mockFile,
            this.editedTrail.main_img
          );
          this.$refs.editDropzone.dropzone.emit(
            "thumbnail",
            mockFile,
            this.editedTrail.main_img
          );
          this.$refs.editDropzone.dropzone.emit("complete", mockFile);
        }
      }
      if (this.$refs.editDropzoneDetail) {
        this.$refs.editDropzoneDetail.removeAllFiles();
        let mockFile = {
          accepted: true,
          cropped: true,
          kind: "image",
          ...this.editedTrail,
        };
        let elements =
          this.$refs.editDropzoneDetail.$el.getElementsByClassName(
            "dz-preview"
          );
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
        for (let i = 0; i < this.editedTrail.images.length; i++) {
          mockFile.removeItemIndex = i;
          this.$refs.editDropzoneDetail.manuallyAddFile(
            mockFile,
            this.editedTrail.images[i]
          );
          this.$refs.editDropzoneDetail.dropzone.emit(
            "thumbnail",
            mockFile,
            this.editedTrail.images[i]
          );
          this.$refs.editDropzoneDetail.dropzone.emit("complete", mockFile);
        }
      }
      this.dialogEditTrail = true;
    },
    async changeStatus(trail) {
      if (trail.active) {
        trail.active = 0;
      } else {
        trail.active = 1;
      }
      await this.editTrail(trail);
    },
    async createNewTrail() {
        this.createdTrail.hotel_id = +this.$route.params.id;
        let id;
        if(this.item.type === 13){
            id = +(this.selectedMenuID+""+this.item.id+'000');
        }else{
            id = this.item.id;
        }
      this.createdTrail.menu_id = id;
      this.createdTrail.distance = +this.createdTrail.distance;
      await this.createTrail(this.createdTrail);
      this.successMessage = "New trail created succesfully";
      this.dialogCreateTrail = false;
      this.createdTrail = JSON.parse(JSON.stringify(this.defaultTrail));
      this.$refs.PopupSuccess.open();
    },
    createTrailShow() {
        if(this.$refs.createDropzone){
            this.$refs.createDropzone.removeAllFiles();
        }
        if(this.$refs.createDropzoneDetail){
            this.$refs.createDropzoneDetail.removeAllFiles();
        }
      this.createdTrail = JSON.parse(JSON.stringify(this.defaultTrail));
      this.dialogCreateTrail = true;
    },
    closeDialogCreateTrail() {
      this.dialogCreateTrail = false;
      this.createdTrail = JSON.parse(JSON.stringify(this.defaultTrail));
    },
    handleSuccessEditTrailMainImage(file) {
      this.editedTrail.main_img = file.xhr.response;
    },
    handleRemoveEditTrailMainImage(file) {
      this.editedTrail.main_img = "";
    },
    handleSuccessTrailMainImage(file) {
      this.createdTrail.main_img = file.xhr.response;
    },
    async handleRemoveTrailMainImage(file) {
      if (file.name === this.createdTrail.main_img) {
        await ImagesService.deleteImage(file.name);
        this.createdTrail.main_img = "";
      } else if (file?.xhr?.response === this.createdTrail.main_img) {
        await ImagesService.deleteImage(file.name);
      }
    },
    formatDate(date) {
      return moment(date).format("DD.MM.yyyy HH:mm");
    },
    async handleRemoveEditTrailDetailImage(file) {
      if (this.editedTrail.images.includes(file?.xhr?.response)) {
        this.editedTrail.images.splice(
          this.editedTrail.images.indexOf(file.xhr.response),
          1
        );
        await ImagesService.deleteImage(file.xhr.response);
        return;
      }
      if (this.editedTrail.images.includes(file.name)) {
        this.editedTrail.images.splice(
          this.editedTrail.images.indexOf(file.name),
          1
        );
        await ImagesService.deleteImage(file.name);
      }
    },
    editDropzoneMountDetail() {
      //
    },
    editDropzoneMount() {
      if (this.editedTrail.main_img) {
        let mockFile = {
          accepted: true,
          cropped: true,
          kind: "image",
          ...this.editedTrail,
        };
        this.$refs.editDropzone.manuallyAddFile(
          mockFile,
          this.editedTrail.main_img
        );
        this.$refs.editDropzone.dropzone.emit(
          "thumbnail",
          mockFile,
          this.editedTrail.main_img
        );
        this.$refs.editDropzone.dropzone.emit("complete", mockFile);
      }
    },
    openDeletePopup(item) {
      this.deleteItem = item;
      this.$refs.PopupDeleteCategory.open();
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    openStyles() {
      this.content.styleSettings = !this.content.styleSettings;
    },
    async customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append("lending_settings_id", this.landing.settings_id);
      formData.append("image", _file);
    },
  },
  beforeDestroy() {
    this.showMce = false;
  },
  async mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.showMce = true;
      }, 3000);
    });
  },
};
</script>

<style scoped></style>
