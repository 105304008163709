<template>
    <div style="padding-top:15px;">
        <div>
            <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Trail detail image settings</label>
            </div>
          <div style="padding: 0 15px;">
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="min-width:200px;"
              >Height <b>{{ content.styles.trail.img_detail_height }}</b> px</label>
              <div class="slider" style="width:100%">
                <v-slider
                    hide-details
                    :min="10"
                    :max="600"
                    v-model="content.styles.trail.img_detail_height"
                ></v-slider>
              </div>
            </div>
          </div>
        </div>
        <div>
            <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Point image settings</label>
            </div>
          <div style="padding:0 15px;">
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="min-width:200px;"
              >Height <b>{{ content.styles.points.listing_item_img_height }}</b> px</label>
              <div class="slider" style="width:100%">
                <v-slider
                    hide-details
                    :min="10"
                    :max="600"
                    v-model="content.styles.points.listing_item_img_height"
                ></v-slider>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"typeTrailPointsStyles",
        props:['content'],
    }
</script>

<style lang="scss" scoped>

</style>