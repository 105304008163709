<template>
    <div>
        <template v-if="page === 'list'">
            <typeTrailList :selectedMenuID="selectedMenuID" @openTrailPoints="openPoints" @openMap="openMap" :item="item" :content="content"/>
        </template>
        <template v-if="page === 'map'">
            <typeTrailMap :selectedMenuID="selectedMenuID" @openTrails="openTrails" :content="content"/>
        </template>
        <template v-if="page === 'points'">
            <typeTrailPointsList  :selectedMenuID="selectedMenuID" @openTrails="openTrails" :content="content"/>
        </template>
        <template v-if="page === 'detail'">
            <typeTrailPointDetail  :selectedMenuID="selectedMenuID" :content="content"/>
        </template>
    </div>
</template>

<script>
import { mapMutations, mapState,mapActions } from 'vuex'
import typeTrailList from "./Trails/typeTrailList.vue"
import typeTrailPointsList from "./Trails/typeTrailPoints.vue"
import typeTrailMap from "./Trails/typeTrailMap.vue"
import typeTrailPointDetail from "./Trails/typeTrailPointDetail"
    export default {
        name:"typeTrails",
        data(){
            return{
            }
        },
        props:['item','content','selectedMenuID'],
        components:{
            typeTrailList,
            typeTrailPointsList,
            typeTrailMap,
            typeTrailPointDetail
        },
        computed:{
            ...mapState('trails',['trail','page']),
        },
        methods:{
            ...mapActions('trails',['getTrailList']),
            ...mapMutations('trails',['setTrail','setPage']),
            openMap(item){
                this.setTrail(item);
                this.setPage('map');
            },
            openTrails(){
                this.setPage('list');
                this.setTrail(null);
            },
            openPoints(item){
                this.setTrail(item);
                this.setPage('points');
            }
        },
        async mounted() {
            let id;
            if(this.item.type === 13){
                id = +(this.selectedMenuID+""+this.item.id+'000');
            }else{
                id = this.item.id;
            }
            const payload = {
                hotel_id: +this.$route.params.id,
                menu_id: id,
            };
            await this.getTrailList(payload);
        }
    }
</script>

<style lang="scss" scoped>

</style>