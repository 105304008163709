<template>
  <div>
    <div>
      <div class="control">
        <label class="label" style="font-weight: bold; font-size: 20px"
          >Block settings</label
        >
      </div>
      <div style="padding: 0 15px;">
        <div style="display:flex;align-items:center;margin-bottom:15px;">
          <label class="label" style="display:block;width:200px;margin-bottom:0;margin-right:8px;">Vector</label>
          <v-select
              flat
              dense
              outlined
              hide-details
              placeholder="Select vector"
              :items="vector_types"
              item-text="label"
              item-value="value"
              class="canvas__select"
              v-model="content.styles.trail.vector"
              style="max-width: 250px;"
          >
            <template v-slot:selection="{ item }">
              <div :style="`font-family:'${item.value}',sans-serif`">
                {{ item.label }}
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div :style="`font-family:'${item.value}',sans-serif`">
                {{ item.label }}
              </div>
            </template>
          </v-select>
        </div>
        <div>
          <div style="display:flex;align-items:center;" v-if="content.styles.trail.vector !== 'vertical'">
            <label class="label" style="display:block;min-width:200px;margin-bottom:0;">
              Width <b>{{ content.styles.trail.width }}px</b>
            </label>
            <div class="control" style="width:100%;">
              <div class="slider">
                <v-slider
                    hide-details
                    :max="500"
                    :min="33"
                    v-model="content.styles.trail.width"
                ></v-slider>
              </div>
            </div>
          </div>
          <div style="display:flex;align-items:center;margin-bottom:15px;">
            <label class="label" style="display:block;min-width:200px;margin-bottom:0;">
              Height <b>{{ content.styles.trail.height }}px</b>
            </label>
            <div class="control" style="width:100%;">
              <div class="slider">
                <v-slider
                    hide-details
                    :max="600"
                    :min="30"
                    v-model="content.styles.trail.height"
                ></v-slider>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div>
      <div class="control">
        <label class="label" style="font-weight: bold; font-size: 20px"
          >General Settings</label
        >
      </div>
      <!-- <div>
          <label class="label">Name position</label>
          <v-select :items="['bottom','inside']" v-model="content.styles.category.show_name" outlined dense hide-details
                    style="margin-bottom: 15px;max-width:250px;"></v-select>
      </div> -->
      <div style="padding:0 15px;">
        <div style="display: flex; align-items: center; margin-bottom: 15px">
          <label class="label" style="display:block;min-width:200px;margin-bottom:0;margin-right: 8px">
            Font Color
          </label>
          <div class="control">
            <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_text_color"
            >
              <template v-slot:activator="{ on }">
                <div
                    class="custom__menu--color"
                    :style="`background-color:${content.styles.trail.nameColor}`"
                    v-on="on"
                />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="content.styles.trail.nameColor" flat />
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </div>

        <div style="display:flex;align-items:center;margin-bottom:15px;">
          <label class="label" style="display:block;min-width:200px;margin-bottom:0;">
            Font Size <b>{{ content.styles.trail.nameFontSize }}px</b>
          </label>
          <div class="control" style="width:100%;">
            <div class="slider">
              <v-slider
                  hide-details
                  :max="40"
                  :min="10"
                  v-model="content.styles.trail.nameFontSize"
              ></v-slider>
            </div>
          </div>
        </div>

        <div style="display:flex;align-items:center;margin-bottom:15px;">
          <label class="label" style="display:block;min-width:200px;margin-bottom:0;">
            Font Weight <b>{{ parseInt(content.styles.trail.nameFontWeight, 10) / 10 }}%</b>
          </label>
          <div class="control" style="width:100%;">
            <div class="slider">
              <v-slider
                  hide-details
                  :max="1000"
                  :min="100"
                  v-model="content.styles.trail.nameFontWeight"
                  :step="100"
              ></v-slider>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: space-between;margin-bottom:15px;">
          <div style="display:flex;align-items:center;">
            <label class="label" style="display:block;min-width:200px;margin-bottom:0;margin-right:8px">
              Font Style <b>{{ content.styles.trail.nameFontStyle }}</b>
            </label>
            <div class="control">
              <v-radio-group row hide-details v-model="content.styles.trail.nameFontStyle">
                <v-radio
                    v-for="(type, typeIndex) in fontStyleTypes"
                    :key="type.id"
                    :label="type.label"
                    :value="type.value"
                    :style="`margin-left:${typeIndex ? '15px' : 0}`"
                >
                </v-radio>
              </v-radio-group>
            </div>
          </div>
          <div style="display:flex;align-items:center;">
            <label class="label" style="display:block;min-width:200px;margin-bottom:0;"> Font Family </label>

            <v-select
                flat
                dense
                outlined
                hide-details
                placeholder="Select Font Family"
                :items="fontFamilyTypes"
                item-text="label"
                item-value="value"
                class="canvas__select"
                v-model="content.styles.trail.nameFontFamily"
                style="max-width: 250px"
            >
              <template v-slot:selection="{ item }">
                <div :style="`font-family:'${item.value}',sans-serif`">
                  {{ item.label }}
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div :style="`font-family:'${item.value}',sans-serif`">
                  {{ item.label }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>


      <div class="control">
        <label class="label" style="font-weight: bold; font-size: 20px"
          >Image Settings</label
        >
      </div>
      <div style="padding:0 15px;">
          <div style="margin-bottom:15px;">
            <label class="label" style="margin-bottom: 15px"
            >Choose your image style</label
            >
            <div class="socials-style">
            <div
                class="socials-style__item socials-style__item_default socials-style__listing__vertical"
                style="height: 110px; width: 100px"
                :class="{
            active: content.styles.trail.displayType === 'default',
          }"
                @click="changeListingStyle('default')"
            >
              <img src="@/assets/icons/default.png" alt="" />
            </div>
            <div
                class="socials-style__item socials-style__item_default socials-style__listing__inside"
                style="height: 110px; width: 100px"
                :class="{
            active: content.styles.trail.displayType === 'inside',
          }"
                @click="changeListingStyle('inside')"
            >
              <img src="@/assets/icons/inside.png" alt="" />
            </div>
            <div class="socials-style" style="margin: 0">
              <div
                  style="width: 240px"
                  class="socials-style__item socials-style__item_default socials-style__listing__horizontal"
                  :class="{
              active: content.styles.trail.displayType === 'horizontal',
            }"
                  @click="changeListingStyle('horizontal')"
              >
                <img src="@/assets/icons/horizontal.png" alt="" />
              </div>
            </div>
          </div>
          </div>
        <div style="display:flex;align-items:center;margin-bottom:15px;">
          <label class="label" style="display:block;min-width:200px;margin-bottom:0;">
            Height <b>{{ content.styles.trail.img_height}} px</b>
          </label>
          <div class="control" style="width:100%;">
            <div class="slider">
              <v-slider
                  hide-details
                  :max="600"
                  :min="10"
                  v-model="content.styles.trail.img_height"
              ></v-slider>
            </div>
          </div>
        </div>
        <div style="display:flex;align-items:center;margin-bottom:15px;">
          <label class="label" style="display:block;min-width:200px;margin-bottom:0;">
            Width <b>{{ content.styles.trail.img_width}} %</b>
          </label>
          <div class="control" style="width:100%;">
            <div class="slider">
              <v-slider
                  hide-details
                  :max="100"
                  :min="10"
                  v-model="content.styles.trail.img_width"
              ></v-slider>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="control">
      <label class="label" style="font-weight: bold; font-size: 20px"
        >Detail Image Settings</label
      >
    </div>
    <div style="padding:0 15px">
      <div style="display:flex;align-items:center;margin-bottom:15px;">
        <label class="label" style="display:block;min-width:200px;margin-bottom:0;">
          Height <b>{{ content.styles.trail.img_detail_height}} px</b>
        </label>
        <div class="control" style="width:100%;">
          <div class="slider">
            <v-slider
                hide-details
                :min="10"
                :max="600"
                v-model="content.styles.trail.img_detail_height"
            ></v-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "typeTrailsStyles",
  props: ["content"],
  data() {
    return {
      show_text_color: false,
      vector_types: [
        { id: 1, value: "vertical", label: "Vertical" },
        { id: 0, value: "horizontal", label: "Horizontal" },
      ],
      fontStyleTypes: [
        { id: 1, value: "normal", label: "Normal" },
        { id: 0, value: "italic", label: "Italic" },
      ],
      fontFamilyTypes: [
        { id: 0, value: "Arial", label: "Arial" },
        { id: 1, value: "SF UI Text", label: "SF UI Text" },
        { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
        { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
        { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
        { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
        { id: 6, value: "Geometria", label: "Geometria" },
        { id: 7, value: "DIN Pro", label: "DIN Pro" },
        { id: 8, value: "Circe", label: "Circe" },
        { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
        { id: 10, value: "Acrom", label: "Acrom" },
        { id: 11, value: "Inter", label: "Inter" },
      ],
    };
  },
  methods: {
    changeListingStyle(style) {
      this.content.styles.trail.displayType = style;
    },
  },
};
</script>

<style lang="scss" scoped></style>
